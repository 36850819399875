import { Cesium3DTileset, Ion, Viewer } from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { useEffect } from "react";

// The URL on your server where CesiumJS's static files are hosted.
window.CESIUM_BASE_URL = "/";

export default function Home() {
  Ion.defaultAccessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MzExNWEzNS04MmU4LTRlMGMtYTRmNC1iZmFiNjExZGQ1YjUiLCJpZCI6MjUyMTgyLCJpYXQiOjE3MzA0NTE1MDF9.oHMa_wTJHC0C9ICBMttoqKM8E58pTi5-KNWuODPZH_o";

  async function initializeMap() {
    // Initialize the Cesium Viewer in the HTML element with the `cesiumContainer` ID.
    const viewer = new Viewer("cesiumContainer", {
      baseLayerPicker: false,
      scene3DOnly: true,
      infoBox: false,
    });

    try {
      const tileset = await Cesium3DTileset.fromIonAssetId(2275207);
      viewer.scene.primitives.add(tileset);
    } catch (error) {
      console.log(error);
    }

    // Hide cesium timeline
    viewer.animation.container.style.visibility = "hidden";
    viewer.timeline.container.style.visibility = "hidden";
    viewer.forceResize();

    // Pan to Ghana
    viewer.geocoder.viewModel.searchText = "Ghana";
    // viewer.geocoder.viewModel.flightDuration = 0.0;
    viewer.geocoder.viewModel.search();
    viewer.resize = "14";
  }

  useEffect(() => {
    initializeMap();
  }, []);

  return (
    <main>
      <div id="cesiumContainer" style={{ height: "100vh" }}></div>
    </main>
  );
}
